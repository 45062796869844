@import '../../../sass/config';

.section_login {
  width: 90%;
  max-width: 60rem;
  margin: 20rem auto 10rem;
}

.section_login_title {
  margin-bottom: 6.4rem;

  font-size: 4.8rem;
  color: $__clr_text;
  text-transform: uppercase;
}

.section_login_form {
  width: 100%;
}

.section_login_form_btns {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .section_login {
    width: 90%;
  }
  .section_login_title {
    font-size: 3.6rem;
    margin-bottom: 5.6rem;
  }
}

@media only screen and (max-width: 770px) {
  .section_login_title {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
}

@media only screen and (max-width: 690px) {
  .section_login_form {
    margin-top: 4.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .section_login_form {
    margin-top: 9.4rem;
  }
}
