@import '../../../sass/config';

.game_box {
  position: relative;
  width: 45%;
  margin-bottom: 8rem;
  color: $__clr_text;
  border: 0.2rem solid rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: inset 4px -4px 250px rgba(255, 255, 255, 0.1);
}

.game_img {
  width: 100%;
}

.game_img_placeholder {
  width: 100%;
  aspect-ratio: 454 / 212;
  background-color: $__clr_placeholder;
}

.game_content_container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  padding: 1.6rem 1.4rem 6.6rem 1.4rem;
}

.game_content_title {
  font: bold 3rem $__font_primary;
}

.game_content_genre {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.6rem;

  font: italic 1.8rem $__font_primary;
}

.game_content_genre_title {
  font-style: normal;
}

.game_content_desc,
.game_content_desc_active {
  transition: max-height 0.3s ease-out;
}

.game_content_desc {
  max-height: 5.4rem;
  height: 10rem;
  overflow: hidden;
}

.game_content_desc_active {
  flex: 1;

  max-height: 10rem;
  overflow: auto;
}

.game_content_desc_text,
.game_content_desc_text_active {
  font-size: 1.8rem;
}

.game_content_desc_text {
  display: -webkit-box;

  overflow: hidden;

  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.game_content_desc_read_more,
.game_content_desc_read_more_placeholder {
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: start;
  color: $__clr_background_icon;

  transition: color 0.2s ease-out;
}

.game_content_desc_read_more {
  cursor: pointer;
}

.game_content_desc_read_more_placeholder {
  cursor: default;
}

.game_content_desc_read_more:hover {
  color: $__clr_main;
}

.game_btn_container {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  height: 5rem;
  border-top: 2px solid rgba(255, 255, 255, 0.15);
}

.game_btn_steam,
.game_btn_trailer,
.game_btn_steam_placeholder,
.game_btn_trailer_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.6rem;

  width: 50%;
  height: 100%;
  border: none;

  font: bold 2rem $__font_primary;
  text-transform: uppercase;
}

.game_btn_steam,
.game_btn_trailer {
  cursor: pointer;
}

.game_btn_steam_placeholder,
.game_btn_trailer_placeholder {
  cursor: default;
}

.game_btn_steam,
.game_btn_steam_placeholder {
  color: $__clr_background;
  background-color: $__clr_main;
  transition: 0.2s background-color ease-out;
}

.game_btn_steam:hover {
  background-color: $__clr_main_hover;
}

.game_btn_trailer,
.game_btn_trailer_placeholder {
  color: #000;
  background-color: $__clr_secondary;
  transition: 0.2s background-color ease-out;
}

.game_btn_trailer:hover {
  background-color: $__clr_secondary_hover;
}

.game_btn_icon {
  width: 2.7rem;
}

.game_content_title_placeholder,
.game_content_genre_placeholder,
.game_content_desc_placeholder {
  background-color: $__clr_placeholder;
  border-radius: 0.4rem;
}

.game_content_title_placeholder {
  width: 50%;
  height: 4rem;
}

.game_content_genre_placeholder {
  width: 20%;
  height: 2.5rem;
}

.game_content_desc_placeholder {
  width: 100%;
  height: 5rem;
}

@media only screen and (max-width: 1100px) {
  .game_title {
    font-size: 2.4rem;
  }
  .game_genre,
  .game_desc {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 768px) {
  .game_box {
    max-width: 50rem;
    width: 100%;
    margin: 0 auto 4rem;
  }
  .game_title {
    font-size: 2rem;
  }
}
