@import '../../sass/config';

.section_about {
  width: 80%;
  min-height: calc(100vh - 20rem);
  margin: 20rem auto 0;
}

.section_about_title {
  font-size: 4.8rem;
  color: $__clr_text;
  text-transform: uppercase;
  margin-bottom: 6.4rem;
}

.section_about_text {
  font-size: 1.8rem;
  color: $__clr_text;
}

@media only screen and (max-width: 1200px) {
  .section_about {
    width: 90%;
  }
  .section_about_title {
    font-size: 3.6rem;
    margin-bottom: 5.6rem;
  }
}

@media only screen and (max-width: 770px) {
  .section_about_title {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
}

@media only screen and (max-width: 690px) {
  .section_about_text {
    font-size: 1.6rem;
  }
}
