@import '../../../sass/config';

.section_profile {
  width: 90%;
  max-width: 60rem;
  margin: 20rem auto 10rem;
}

.section_profile_title {
  margin-bottom: 6.4rem;

  font-size: 4.8rem;
  color: $__clr_text;
  text-transform: uppercase;
}

.section_profile_form {
  width: 100%;
}

.section_profile_form_btns {
  display: flex;
  justify-content: space-between;
}

.section_profile_form_btn_submit,
.section_profile_form_btn_submit_failed,
.section_profile_form_btn_forgot_password {
  padding: 1.4rem 5.6rem;
  cursor: pointer;

  font: bold 1.8rem $__font_primary;
  text-transform: uppercase;
  letter-spacing: 0.2rem;

  border: none;
  transition: background-color 0.2s, color 0.2s ease-out;
}

.section_profile_form_btn_submit {
  color: $__clr_background;
  background-color: $__clr_main;
}
.section_profile_form_btn_submit_failed {
  background-color: $__clr_secondary;
}

.section_profile_form_btn_submit:hover {
  background-color: $__clr_main_hover;
}

.section_profile_form_btn_forgot_password {
  color: $__clr_main;
  background-color: transparent;
}

.section_profile_form_btn_forgot_password:hover {
  color: $__clr_main_hover;
}

@media only screen and (max-width: 1200px) {
  .section_profile {
    width: 90%;
  }
  .section_profile_title {
    font-size: 3.6rem;
    margin-bottom: 5.6rem;
  }
}

@media only screen and (max-width: 820px) {
  .section_profile_form_btn_submit,
  .section_profile_form_btn_forgot_password {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 770px) {
  .section_profile_title {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
}

@media only screen and (max-width: 690px) {
  .section_profile_form {
    margin-top: 4.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .section_profile_form {
    margin-top: 9.4rem;
  }
}

@media only screen and (max-width: 550px) {
  .section_profile_form_btn_submit,
  .section_profile_form_btn_forgot_password {
    padding: 1.4rem 5rem;

    font-size: 1.4rem;
  }
}
