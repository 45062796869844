@import '../../../sass/config';

.animated-slide-content {
  &-enter {
    opacity: 0;
    transform: translateY(-15px);
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: 0.3s ease-out;
    transition-delay: 0.7s;
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(-15px);
    transition: 0.3s ease-out;
  }
}

.animated-slide-right {
  &-enter {
    opacity: 0;
    transform: translateX(-5rem);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(-2.5rem);
    transition: 0.3s ease-out;
    transition-delay: 0.5s;
  }

  &-exit {
    opacity: 1;
    transform: translateX(-2.5rem);
  }

  &-exit-active {
    opacity: 0;
    transform: translateX(0);
    transition: 0.3s ease-out;
    transition-delay: 0.2s;
  }
}

.animated-slide-left {
  &-enter {
    opacity: 0;
    transform: translateX(0);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(-2.5rem);
    transition: 0.3s ease-out;
    transition-delay: 0.6s;
  }

  &-exit {
    opacity: 1;
    transform: translateX(-2.5rem);
  }

  &-exit-active {
    opacity: 0;
    transform: translateX(-5rem);
    transition: 0.3s ease-out;
  }
}
