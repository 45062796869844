@import '../../../sass/config';

circle {
  stroke: $__clr_text;
  fill: transparent;

  transition: stroke-dashoffset 1s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
