@import '../../../../sass/config';

.modal_large,
.modal_small {
  position: fixed;
  transform: translateX(-50%);
  top: 10%;
  left: 50%;

  width: 90%;
  max-height: 85vh;
  overflow: auto;
  background: $__clr_background;
  box-sizing: border-box;

  border: 0.2rem solid $__clr_main_border;
  border-radius: 0.2rem;
}

.modal_large {
  max-width: 90rem;
}

.modal_small {
  max-width: 40rem;
}

.modal_large_img,
.modal_small_img {
  width: 100%;
  height: 100%;
  max-height: 70vh;
  box-sizing: border-box;

  object-fit: contain;
  border-bottom: 0.2rem solid $__clr_main_border;
}

.modal_large_btns,
.modal_small_btns {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;

  width: 100%;
  padding: 0.8rem 1.6rem;
}

.modal_large_btn_choose_file,
.modal_small_btn_choose_file {
  padding: 0.8rem 2.4rem;
  background-color: $__clr_main;
  cursor: pointer;

  font: bold 1.8rem $__font_primary;
  color: $__clr_background;
  text-transform: uppercase;

  border-radius: 0.1rem;
  transition: 0.2s background-color ease-out;
}

.modal_large_btn_choose_file:hover,
.modal_small_btn_choose_file:hover {
  background-color: $__clr_main_hover;
}

.modal_large_btn_choose_file_input,
.modal_small_btn_choose_file_input {
  display: none;
}

.modal_large_btn_confirm,
.modal_large_btn_decline,
.modal_small_btn_confirm,
.modal_small_btn_decline {
  display: flex;
  align-items: center;

  padding: 0.6rem 1.8rem;
  cursor: pointer;

  font: bold 1.6rem $__font_primary;
  color: $__clr_background;
  text-transform: uppercase;

  border: none;
  border-radius: 0.1rem;
  transition: 0.2s background-color ease-out;
}

.modal_large_btn_confirm,
.modal_small_btn_confirm {
  background-color: $__clr_confirm;
}

.modal_large_btn_confirm:hover,
.modal_small_btn_confirm:hover {
  background: $__clr_confirm_hover;
}

.modal_large_btn_decline,
.modal_small_btn_decline {
  background-color: $__clr_secondary;
}

.modal_large_btn_decline:hover,
.modal_small_btn_decline:hover {
  background-color: $__clr_secondary_hover;
}

.modal_large_btn_confirm_icon,
.modal_large_btn_decline_icon,
.modal_small_btn_confirm_icon,
.modal_small_btn_decline_icon {
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.8rem;
}

.modal_large_btn_desc,
.modal_small_btn_desc {
  padding: 0.8rem 0 0 0;

  font-size: 1.8rem;
  color: $__clr_text;
}

.error_msg {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  font: 1.8rem $__font_primary;
  text-transform: capitalize;
  color: $__clr_text;
}

.error_msg_icon {
  height: 2.8rem;
}

@media only screen and (max-width: 770px) {
  .modal_large_btn_choose_file,
  .modal_small_btn_choose_file {
    font-size: 1.6rem;
  }
}
