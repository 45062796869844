div.animated-modal {
  &-enter {
    opacity: 0.5;
    transform: translateX(-50%) scale(0.5);
  }

  &-enter-active {
    opacity: 1;
    transform: translateX(-50%) scale(1);
    transition: opacity 0.1s, transform 0.1s ease-out;
  }

  &-exit {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }

  &-exit-active {
    opacity: 0.5;
    transform: translateX(-50%) scale(0.5);
    transition: opacity 0.1s, transform 0.1s ease-out;
  }
}
