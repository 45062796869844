@import '../../../sass/config';

.progress {
  width: 100%;
  height: 3rem;
  background-color: $__clr_main;
  box-sizing: border-box;

  border: 0.2rem solid $__clr_main_border;
  border-radius: 1rem;
}

.bar {
  position: relative;

  height: 100%;
  background-color: $__clr_main_dark;

  font: bold 2rem $__font_primary;

  border-radius: 1rem;
  transition: width 0.2s ease-out;
}

.bar_text {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  color: $__clr_text;
}
