@import '../../../../sass/config';

.modal_close_btn {
  position: absolute;
  top: 0;
  right: 0;

  cursor: pointer;

  border: none;
}

.modal_close_btn_icon {
  position: absolute;
  top: 0;
  right: 0;

  width: 3.6rem;
  height: 3.6rem;
  padding: 0.4rem;
  background-color: $__clr_secondary;

  z-index: 400;

  border-radius: 0.1rem;
  transition: 0.2s background-color ease-out;
}
