@import '../../../sass/config';

.section_games_btn_container {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-bottom: 8rem;
}

.section_games_btn_save_changes_container {
  position: relative;

  width: 45%;
  max-width: 37.1rem;
  margin-left: 0.8rem;
}

.section_games_btn_add_new {
  padding: 1.2rem 0;
  background-color: $__clr_main;
  cursor: pointer;

  font: bold 2rem $__font_primary;
  color: $__clr_background;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  border: none;
  transition: 0.2s background-color ease-out;
}

.section_games_btn_add_new {
  width: 45%;
  max-width: 37.1rem;
  margin-right: 0.8rem;
}

.section_games_btn_add_new:hover {
  background-color: $__clr_main_hover;
}

.section_games_filter_first,
.section_games_filter_last {
  width: 100%;
  background: rgba(45, 242, 255, 0.15);
  border: 1px solid rgba(66, 172, 234, 0.35);
  padding: 1.6rem 0;
}

.section_games_filter_first {
  margin-bottom: 6.4rem;
}

.section_games_filter_last {
  margin-top: 4rem;
  margin-bottom: 10.4rem;
}

.section_games_filter_text {
  font-family: $__font_primary;
  font-weight: bold;
  font-size: 2.8rem;
  font-style: italic;
  text-align: center;
  text-transform: uppercase;
  color: $__clr_text;
  letter-spacing: 0.03em;
}

.games_display_format {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto 4.6rem;
  height: 6.4rem;
}

.games_display_format_txt {
  font-family: $__font_primary;
  font-size: 2rem;
  letter-spacing: 0.03em;
  color: $__clr_text;
}

.games_display_format_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-left: 4rem;
  cursor: pointer;
}

.games_display_format_icon {
  width: 6.4rem;
  height: 6.4rem;
  padding: 1.2rem;

  background: rgba(255, 255, 255, 0.12);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: inset 0.4rem --0.4rem 25rem rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 0.1rem;
}

.section_games_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.message_no_data_container {
  margin: 5.6rem auto;
  padding: 3.6rem;
  width: 60%;
  background: rgba(255, 255, 255, 0.05);
  border: 0.2rem solid rgba(255, 255, 255, 0.15);
  box-shadow: inset 0.4rem -0.4rem 25rem rgba(255, 255, 255, 0.1);
}

.message_no_data_text {
  font: bold 2rem/4rem $__font_primary;
  text-align: center;
  color: $__clr_text;
}

.message_no_data_add {
  cursor: pointer;

  color: $__clr_background_icon;
}

.message_no_data_add:hover {
  color: $__clr_main;
}

@media only screen and (min-width: 1900px) {
  .section_games_container {
    max-width: 152rem;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1200px) {
  .section_games_btn_container {
    margin-bottom: 6rem;
  }
  .section_games_btn_add_new {
    font-size: 1.8rem;
    padding: 1rem 0;
  }
  .section_games_filter_first {
    margin-bottom: 5.6rem;
    padding: 0.8rem 0;
  }
  .section_games_filter_last {
    margin-top: 3.2rem;
    padding: 0.8rem 0;
  }
  .section_games_filter_text {
    font-size: 2.4rem;
  }
  .games_display_format {
    width: 90%;
  }
  .section_games_container {
    width: 90%;
  }
  .message_no_data_text {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 770px) {
  .section_games_btn_container {
    margin-bottom: 4rem;
  }
  .section_games_btn_add_new {
    font-size: 1.6rem;
    padding: 0.8rem 0;
  }
  .section_games_filter_first {
    margin-bottom: 4rem;
    padding: 0.2rem 0;
  }
  .section_games_filter_last {
    margin-top: 1.6rem;
    padding: 0.2rem 0;
  }
  .section_games_filter_text {
    font-size: 1.8rem;
  }
  .games_display_format_btn {
    margin-left: 3.2rem;
  }
  .games_display_format_icon {
    width: 5.6rem;
    height: 5.6rem;
    padding: 1.2rem;
  }
  .message_no_data_text {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 480px) {
  .games_display_format_txt {
    text-transform: capitalize;
  }
  .games_display_format_txt_extra {
    display: none;
  }
}
