$__font_primary: 'Noto Sans', Helvetica, sans-serif;
$__font_secondary: 'Noto Serif', 'Georgia', serif;

@font-face {
  font-family: 'notoSans';
  src: url('../assets/Fonts/noto sans/regular.woff2') format('woff2'),
    url('../assets/Fonts/noto sans/regular.woff') format('woff'),
    url('../assets/Fonts/noto sans/regular.ttf') format('truetype');
  font-style: none;
  font-weight: normal;
}

@font-face {
  font-family: 'notoSans';
  src: url('../assets/Fonts/noto sans/italic.woff2') format('woff2'),
    url('../assets/Fonts/noto sans/italic.woff') format('woff'),
    url('../assets/Fonts/noto sans/italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'notoSans';
  src: url('../assets/Fonts/noto sans/bold.woff2') format('woff2'),
    url('../assets/Fonts/noto sans/bold.woff') format('woff'),
    url('../assets/Fonts/noto sans/bold.ttf') format('truetype');
  font-style: none;
  font-weight: bold;
}

@font-face {
  font-family: 'notoSans';
  src: url('../assets/Fonts/noto sans/boldItalic.woff2') format('woff2'),
    url('../assets/Fonts/noto sans/boldItalic.woff') format('woff'),
    url('../assets/Fonts/noto sans/boldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'notoSerif';
  src: url('../assets/Fonts/noto serif/regular.woff2') format('woff2'),
    url('../assets/Fonts/noto serif/regular.woff') format('woff'),
    url('../assets/Fonts/noto serif/regular.ttf') format('truetype');
  font-style: none;
  font-weight: normal;
}

@font-face {
  font-family: 'notoSerif';
  src: url('../assets/Fonts/noto serif/italic.woff2') format('woff2'),
    url('../assets/Fonts/noto serif/italic.woff') format('woff'),
    url('../assets/Fonts/noto serif/italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'notoSerif';
  src: url('../assets/Fonts/noto serif/bold.woff2') format('woff2'),
    url('../assets/Fonts/noto serif/bold.woff') format('woff'),
    url('../assets/Fonts/noto serif/bold.ttf') format('truetype');
  font-style: none;
  font-weight: bold;
}

@font-face {
  font-family: 'notoSerif';
  src: url('../assets/Fonts/noto serif/boldItalic.woff2') format('woff2'),
    url('../assets/Fonts/noto serif/boldItalic.woff') format('woff'),
    url('../assets/Fonts/noto serif/boldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}
