.animated-title {
  &-enter {
    max-width: 20rem;
    opacity: 0.5;
  }

  &-enter-active {
    max-width: 70rem;
    opacity: 1;
    transition: opacity 0.3s, max-width 0.3s ease-out;
  }

  &-exit {
    max-width: 70rem;
    opacity: 1;
  }

  &-exit-active {
    max-width: 20rem;
    opacity: 0.5;
    transition: opacity 0.2s, max-width 0.2s ease-in;
  }
}

.animated-genres-list {
  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 0;
  }

  &-enter-done {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.animated-genres-item {
  &-enter {
    max-width: 10rem;
    opacity: 0.5;
  }

  &-enter-active {
    max-width: 30rem;
    opacity: 1;
    transition: opacity 0.3s, max-width 0.3s ease-out;
  }

  &-exit {
    max-width: 30rem;
    opacity: 1;
  }

  &-exit-active {
    max-width: 10rem;
    opacity: 0.5;
    transition: opacity 0.2s, max-width 0.2s ease-in;
  }
}

.animated-desc {
  &-enter {
    height: 12rem;
    opacity: 0.5;
  }

  &-enter-active {
    height: 20rem;
    opacity: 1;
    transition: opacity 0.3s, height 0.3s ease-out;
  }

  &-exit {
    height: 20rem;
    opacity: 1;
  }

  &-exit-active {
    height: 12rem;
    opacity: 0.5;
    transition: opacity 0.2s, height 0.2s ease-in;
  }
}

.animated-btn {
  &-enter {
    max-width: 20rem;
    opacity: 0.5;
  }

  &-enter-active {
    max-width: 45rem;
    opacity: 1;
    transition: opacity 0.3s, max-width 0.3s ease-out;
  }

  &-exit {
    max-width: 45rem;
    opacity: 1;
  }

  &-exit-active {
    max-width: 20rem;
    opacity: 0.5;
    transition: opacity 0.2s, max-width 0.2s ease-in;
  }
}
