@import '../../../sass/config';

.form_control {
  display: flex;
  flex-direction: row-reverse;

  position: relative;

  width: 100%;
  height: 6.5rem;
  margin-bottom: 3.2rem;
}

.form_control_label {
  display: flex;
  align-items: center;

  position: absolute;
  left: 0.1rem;
  top: 0.1rem;

  width: 11.9rem;
  height: calc(100% - 0.2rem);
  padding: 1.6rem;
  background-color: $__clr_background_label;

  font-size: 1.6rem;
  font-weight: bold;
  color: $__clr_text;
  text-transform: uppercase;

  border-right: 0.1rem solid rgba(255, 255, 255, 0.35);
  border-radius: 0.2rem 0 0 0.2rem;
}

.form_control_text_box,
.form_control_text_box_alert {
  width: 100%;
  height: 100%;
  padding: 1.6rem 1.6rem 1.6rem 13.6rem;
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 1.8rem;
  color: $__clr_text;

  border-radius: 0.2rem;
  transition: 0.2s border-color, 0.2s box-shadow ease-out;
}

.form_control_text_box {
  border: 0.1rem solid rgba(255, 255, 255, 0.35);
}

.form_control_text_box_alert {
  border: 0.1rem solid $__clr_secondary;
  box-shadow: 0 0 1.5rem rgba(232, 50, 39, 0.7);
}

.form_control_text_box_alert_msg {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0.8rem;

  height: 80%;
  padding: 1rem 1rem 1rem 1.6rem;
  background-color: $__clr_background;

  font: bold 1.4rem $__font_primary;
  color: $__clr_text;

  border-radius: 0.2rem;
}

.form_control_text_box_alert_msg_active_icon {
  transform: translateY(0.15rem);

  height: 100%;
}

.form_control_text_box_alert_msg_cross_icon {
  display: none;

  height: 100%;
  background-color: rgba(232, 50, 39, 0.7);
  border-radius: 0.2rem;
  cursor: pointer;

  transform: translateY(0.15rem);
}

.form_control_text_box_alert_msg_cross_icon_modal {
  height: 100%;
  margin-right: 0.4rem;
  background-color: rgba(232, 50, 39, 0.7);
  border-radius: 0.2rem;
  cursor: pointer;

  transform: translateY(0.15rem);
}

.form_control_text_box:focus,
.form_control_text_box_alert:focus {
  border-color: $__clr_main;
  box-shadow: 0 0 1.5rem rgba(46, 205, 255, 0.35);
}

.form_control_text_area_container {
  position: relative;

  width: 100%;
  height: 39.8rem;
  margin-bottom: 3.2rem;
}

.form_control_text_area_label {
  display: flex;
  align-items: center;

  width: 12rem;
  height: 6.5rem;
  padding: 1.6rem;
  background-color: $__clr_background_label;

  font-size: 1.6rem;
  font-weight: bold;
  color: $__clr_text;
  text-transform: uppercase;

  border: 0.1rem solid rgba(255, 255, 255, 0.35);
  border-bottom: none;
  border-radius: 0.2rem 0 0 0.2rem;
}

.form_control_text_area,
.form_control_text_area_alert {
  width: 100%;
  height: calc(100% - 6.5rem);
  padding: 1.6rem;
  background-color: rgba(255, 255, 255, 0.1);

  font-size: 1.8rem;
  color: $__clr_text;

  border-radius: 0 0.2rem 0.2rem 0;
  resize: none;
  transition: 0.2s border-color, 0.2s box-shadow ease-out;
}

.form_control_text_area {
  border: 0.1rem solid rgba(255, 255, 255, 0.35);
}

.form_control_text_area_alert {
  border: 0.1rem solid $__clr_secondary;
  box-shadow: 0 0 1.5rem rgba(232, 50, 39, 0.7);
}

.form_control_text_area_alert_msg {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  position: absolute;

  bottom: 0.8rem;
  right: 0.8rem;

  height: 5.2rem;
  padding: 1rem 1rem 1rem 1.6rem;
  background-color: $__clr_background;
  border-radius: 0.2rem;

  color: $__clr_text;
  font: bold 1.4rem $__font_primary;
}

.form_control_text_area_alert_msg_active_icon {
  transform: translateY(0.15rem);
}

.form_control_text_area:focus,
.form_control_text_area_alert:focus {
  border-color: $__clr_main;
  box-shadow: 0 0 1.5rem rgba(46, 205, 255, 0.35);
}

.form_control_text_box::placeholder,
.form_control_text_box_alert::placeholder,
.form_control_text_area::placeholder,
.form_control_text_area_alert::placeholder {
  font-family: $__font_primary;
}

@media only screen and (max-width: 1200px) {
  .form_control {
    height: 5.5rem;
  }
  .form_control_text_area_label {
    height: 5.5rem;
  }
  .form_control_text_area,
  .form_control_text_area_alert {
    height: calc(100% - 5.5rem);
  }
  .form_control_text_area_alert_msg,
  .form_control_text_area_alert_msg_active {
    height: 4.4rem;
  }
}

@media only screen and (max-width: 820px) {
  .form_control_label {
    width: 9.9rem;
    font-size: 1.4rem;
    padding: 1.4rem;
  }
  .form_control_text_box,
  .form_control_text_box_alert {
    padding-left: 11.6rem;
  }
  .form_control_text_area_label {
    width: 10rem;
    font-size: 1.4rem;
    padding: 1.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .form_control {
    flex-wrap: wrap;
    height: 5rem;
    margin-bottom: 8.2rem;
  }
  .form_control:nth-child(3) {
    margin-bottom: 3.2rem;
  }
  .form_control_label {
    left: 0;
    top: -100%;
    height: 100%;
    border: 0.1rem solid rgba(255, 255, 255, 0.35);
    border-bottom: none;
  }
  .form_control_text_box,
  .form_control_text_box_alert {
    height: 100%;
    width: 100%;
    padding: 1.6rem;
  }
  .form_control_text_area_alert_msg,
  .form_control_text_area_alert_msg_active {
    height: 4rem;
  }
}

@media only screen and (max-width: 550px) {
  .form_control_text_box_alert_msg_active {
    padding: 1rem;
  }
  .form_control_text_box_alert_msg_cross_icon {
    display: inline-block;

    margin-right: 0.4rem;
  }
}
