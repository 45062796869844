@import '../../sass/config';

.section_contact {
  width: 80%;
  margin: 20rem auto 0;
}

.section_contact_title {
  font-size: 4.8rem;
  color: $__clr_text;
  text-transform: uppercase;
  margin-bottom: 6.4rem;
}

.section_contact_text {
  font-size: 1.8rem;
  color: $__clr_text;
  padding-top: 2.4rem;
}

.section_contact_tabs {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 9.8rem;
  margin-top: 9.3rem;
  padding: 0 10%;
  background: rgba(255, 255, 255, 0.05);
  box-sizing: border-box;

  border: 0.2rem solid rgba(255, 255, 255, 0.15);
  box-shadow: inset 0.4rem -0.4rem 25rem rgba(255, 255, 255, 0.1);
}

.section_contact_tabs_item,
.section_contact_tabs_item_active {
  display: flex;
  align-items: center;

  position: relative;

  height: 100%;
  padding: 0 2.8rem;
  cursor: pointer;

  text-align: center;
  font-size: 2.4rem;
  color: $__clr_text;

  transition: 0.2s color, 0.2s background-color ease-out;
}

.section_contact_tabs_item_active {
  color: $__clr_main;
}

.section_contact_tabs_item:hover,
.section_contact_tabs_item_active:hover {
  background: rgba(255, 255, 255, 0.07);
  color: $__clr_main;
}

.section_contact_tabs_item_text {
  display: inline-block;
}

.section_contact_tabs_item_effect,
.section_contact_tabs_item_effect_active {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -0.2rem;
  height: 0.2rem;

  background-color: $__clr_main;
}

.section_contact_tabs_item_effect {
  width: 0;
}

.section_contact_tabs_item_effect_active {
  width: 100%;
  transition: 0.3s width ease-out;
}

.section_contact_drop_down_container {
  position: relative;
  display: none;
  width: 100%;
}

.section_contact_drop_down_title {
  font-family: $__font_primary;
  font-size: 2rem;
  font-weight: bold;
  color: $__clr_text;
  background-color: transparent;
  width: 90%;
  margin: 4rem auto 2rem;
}

.section_contact_drop_down_container_header_list {
  box-shadow: 0.4rem -0.4rem 25rem rgba(255, 255, 255, 0.1);
}

.section_contact_drop_down_header {
  background-color: rgba(255, 255, 255, 0.05);
  border: 0.1rem solid rgba(255, 255, 255, 0.15);
  border-right: none;
  border-left: none;
  box-sizing: border-box;
  padding: 1.2rem 0;
  transition: 0.2s color, 0.2s background-color ease-out;
  cursor: pointer;
}

.section_contact_drop_down_header:hover {
  background: rgba(255, 255, 255, 0.12);
}

.section_contact_drop_down_header_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $__font_secondary;
  font-size: 1.8rem;
  color: $__clr_main;
  text-transform: capitalize;
  width: 90%;
  margin: 0 auto;
}

.section_contact_drop_down_header_title_icon {
  width: 1.5rem;
}

.section_contact_drop_down_list,
.section_contact_drop_down_list_active {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.2s height ease-in;
  overflow: hidden;
}

.section_contact_drop_down_list {
  height: 0;
}

.section_contact_drop_down_list_active {
  height: 24.5rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.15);
}

.section_contact_drop_down_list_item {
  background-color: rgba(255, 255, 255, 0.05);
  border: none;
  box-sizing: border-box;
  width: 100%;
  padding: 1.2rem 5%;
  text-align: left;
  font-family: $__font_secondary;
  font-size: 1.8rem;
  color: $__clr_text;
  text-transform: capitalize;
  transition: 0.2s color, 0.2s background-color ease-out;
  cursor: pointer;
}

.section_contact_drop_down_list_item:hover {
  background: rgba(255, 255, 255, 0.12);
  color: $__clr_main;
}

.section_contact_tabs_page_form {
  width: 72%;
  margin: 8.9rem auto 42rem;
}

@media only screen and (max-width: 1200px) {
  .section_contact {
    width: 90%;
  }
  .section_contact_title {
    font-size: 3.6rem;
    margin-bottom: 5.6rem;
  }
  .section_contact_tabs {
    padding: 0 5%;
  }
  .section_contact_tabs_item,
  .section_contact_tabs_item_active {
    font-size: 2.2rem;
    padding: 0 2rem;
  }
  .section_contact_tabs_page_form {
    width: 90%;
  }
}

@media only screen and (max-width: 990px) {
  .section_contact_tabs_item,
  .section_contact_tabs_item_active {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 820px) {
  .section_contact_tabs_item,
  .section_contact_tabs_item_active {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 770px) {
  .section_contact_title {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
}

@media only screen and (max-width: 690px) {
  .section_contact_text {
    font-size: 1.6rem;
  }
  .section_contact_tabs {
    display: none;
  }
  .section_contact_drop_down_container {
    display: block;
  }
  .section_contact_tabs_page_form {
    margin-top: 4.4rem;
  }
}

@media only screen and (max-width: 600px) {
  .section_contact_tabs_page_form {
    margin-top: 9.4rem;
  }
}
