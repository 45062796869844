.animated-page {
  &-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  &-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s;
    transition: opacity 0.3s, transform 0.3s ease-in;
  }

  &-exit {
    opacity: 1;
    transform: translateY(0);
  }

  &-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s ease-out;
  }
}
