@import '../../sass/config';

.section_admin_panel {
  width: 80%;
  margin: 20rem auto 0;
}

.section_admin_panel_title {
  font-size: 4.8rem;
  color: $__clr_text;
  text-transform: uppercase;
  margin-bottom: 6.4rem;
}

.section_admin_panel_tabs {
  position: relative;

  width: 100%;
  margin-bottom: 8rem;
}

.section_admin_panel_tabs li {
  display: inline-block;

  width: 50%;
}

.section_admin_panel_tabs_items,
.section_admin_panel_tabs_items_active {
  display: inline-block;

  width: 100%;
  padding: 2.4rem 0;
  cursor: pointer;

  text-align: center;

  border-top: 0.2rem solid rgba(255, 255, 255, 0.15);
  border-bottom: 0.2rem solid rgba(255, 255, 255, 0.15);
  transition: background-color 0.2s ease-out;
}

.section_admin_panel_tabs_items {
  background-color: rgba(255, 255, 255, 0.05);
}

.section_admin_panel_tabs_items:hover {
  background: rgba(255, 255, 255, 0.12);
}

.section_admin_panel_tabs_items_active {
  background-color: rgba(255, 255, 255, 0.12);
}

.section_admin_panel_tabs_item_text {
  color: $__clr_text;
  font-size: 2.4rem;
  text-transform: uppercase;
}

.section_admin_panel_tabs_items_active .section_admin_panel_tabs_item_text {
  color: $__clr_main;
}

.section_admin_panel_tabs_item_active_effect_news,
.section_admin_panel_tabs_item_active_effect_games {
  position: absolute;
  bottom: 0;

  height: 0.2rem;
  width: 50%;
  background-color: $__clr_main;

  transition: left 0.2s ease-out;
}

.section_admin_panel_tabs_item_active_effect_news {
  left: 0;
}

.section_admin_panel_tabs_item_active_effect_games {
  left: 50%;
}

@media only screen and (max-width: 1200px) {
  .section_admin_panel {
    width: 90%;
  }
  .section_admin_panel_title {
    font-size: 3.6rem;
    margin-bottom: 5.6rem;
  }
  .section_admin_panel_tabs_items,
  .section_admin_panel_tabs_items_active {
    padding: 1.6rem 0;
  }
  .section_admin_panel_tabs_item_text {
    font-size: 2.2rem;
  }
  .section_admin_panel_tabs {
    margin-bottom: 5.6rem;
  }
}

@media only screen and (max-width: 770px) {
  .section_admin_panel_title {
    font-size: 2.6rem;
    margin-bottom: 3.2rem;
  }
  .section_admin_panel_tabs_items,
  .section_admin_panel_tabs_items_active {
    padding: 0.8rem 0;
  }
  .section_admin_panel_tabs_item_text {
    font-size: 2rem;
  }
  .section_admin_panel_tabs {
    margin-bottom: 3.4rem;
  }
}
