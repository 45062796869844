@import '../../../../sass/config';

.btn,
.btn_failed,
.btn_succeed {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  padding: 1.4rem 0;
  background-color: transparent;

  font: bold 1.8rem $__font_primary;
  text-transform: uppercase;
  letter-spacing: 0.2rem;

  border: none;
  transition: color 0.2s ease-out;
}

.btn {
  color: $__clr_background_icon;
  cursor: pointer;
}

.btn_failed {
  color: $__clr_secondary;
}

.btn_succeed {
  color: $__clr_confirm;
}

.btn:hover:enabled {
  color: $__clr_main;
}

.btn:disabled {
  cursor: default;
}

@media only screen and (max-width: 820px) {
  .btn,
  .btn_failed,
  .btn_succeed {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 550px) {
  .btn,
  .btn_failed,
  .btn_succeed {
    padding: 1.4rem 5rem;

    font-size: 1.4rem;
  }
}
