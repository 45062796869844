@import '../../../../sass/config';

.error_modal {
  position: fixed;
  transform: translateX(-50%);
  bottom: 5%;
  left: 50%;

  max-width: 60rem;
  background-color: $__clr_background;
  box-sizing: border-box;

  border: solid 0.2rem $__clr_main_border;
  border-radius: 0.2rem;
}

.error_box {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  padding: 3.6rem 2.4rem 2.4rem;
}

.error_box_title {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-transform: capitalize;
  color: $__clr_text;
}

.error_box_text {
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  color: $__clr_text;
}

@media only screen and (max-width: 550px) {
  .error_box_title {
    font-size: 1.6rem;
  }
  .error_box_text {
    font-size: 1.4rem;
  }
}
