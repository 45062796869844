$__clr_text: #e7e7e7;
$__clr_background: #1a1a1e;
$__clr_main: #42acea;
$__clr_main_hover: #8acbf1;
$__clr_main_dark: #0c467c;
$__clr_secondary: #e83227;
$__clr_secondary_hover: #ff2214;
$__clr_section_border: #4f4f4f;
$__clr_img_border: #adadad;
$__clr_background_icon: #0d8bff;
$__clr_background_image: #081d30;
$__clr_background_label: #1d3540;
$__clr_background_text_box: #303034;
$__clr_main_border: rgba(255, 255, 255, 0.15);
$__clr_confirm: #70e347;
$__clr_confirm_hover: #5dfd26;
$__clr_placeholder: rgba(255, 255, 255, 0.05);
